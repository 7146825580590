import { createRouter, createWebHistory } from "vue-router";
import Storage from "../models/storage";

const routes = [
  {
    path: "/",
    component: () => import("../views/ChargeMeHome/Home.vue"),
  },
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/ChargeMeHome/Home.vue"),
  },
  // Shared ---------------------------------------------------
  {
    path: "/LineAuth",
    name: "LineAuth",
    component: () => import("../views/Shared/Login/LineAuth.vue"),
  },
  {
    path: "/EnterpriseLogin",
    name: "EnterpriseLogin",
    component: () => import("../views/Shared/EnterpriseLogin/Login.vue"),
  },
  {
    path: "/google-callback",
    name: "GoogleAuth",
    component: () => import("../views/Shared/Login/GoogleAuth.vue"),
  },
  {
    path: "/login",
    name: "Shared_Login",
    component: () => import("../views/Shared/Login/Login.vue"),
    children: [
      {
        name: "Shared_Register",
        path: "register",
        component: () => import("../components/Shared/Login/RegisterComponent.vue"),
      },
      {
        name: "Shared_ForgetPassword",
        path: "forgetPassword",
        component: () => import("../components/Shared/Login/ForgetPassword.vue"),
      },
      {
        name: "Shared_ResetPassword",
        path: "resetPassword",
        component: () => import("../components/Shared/Login/ResetPassword.vue"),
      },
      {
        name: "Shared_VerifyEmail",
        path: "verifyEmail",
        component: () => import("../components/Shared/Login/VerifyEmailComponent.vue"),
      },
    ],
  },
  {
    path: "/EmailVerify",
    name: "Shared_EmailVerify",
    component: () => import("../views/Shared/Login/EmailVerify.vue"),
  },
  {
    path: "/BindLine",
    name: "Shared_BindLine",
    component: () => import("../views/Shared/Login/BindLine.vue"),
  },
  {
    path: "/MemberCenter",
    name: "Shared_MemberCenter",
    redirect: "/MemberCenter/member",
    component: () => import("../views/Shared/MemberCenter/MemberCenter.vue"),
    children: [
      {
        path: "member",
        name: "Shared_Member",
        component: () => import("../views/Shared/MemberCenter/Member.vue"),
      },
      {
        path: "profile",
        name: "Shared_Profile",
        component: () => import("../views/Shared/MemberCenter/Profile.vue"),
      },
      {
        path: "order",
        name: "Shared_Order",
        component: () => import("../views/Shared/MemberCenter/Order.vue"),
      },
    ],
  },
  {
    path: "/MessageBoard",
    name: "Shared_MessageBoard",
    component: () => import("../views/Shared/SharedPage/MessageBoard.vue"),
    props: true,
  },
  {
    path: "/About",
    name: "Shared_About",
    component: () => import("../views/Shared/About/About.vue"),
    props: true,
  },
  // 媒合 ChargeMeTribe ---------------------------------------------------
  {
    path: "/ChargeMeTribe",
    name: "ChargeMeTribeIndex",
    component: () => import("../views/ChargeMeTribe/HomeViewPage_Post.vue"),
    // beforeEnter: (to, from, next) => {
    //   var loggedIn = localStorage.getItem("user");
    //   loggedIn = JSON.parse(loggedIn)
    //   next(`/ChargeMeTribe/${loggedIn.id}`);
    // }
  },
  {
    path: "/ChargeMeTribe/:Id",
    name: "ChargeMeTribe",
    component: () => import("../views/ChargeMeTribe/HomeViewPage_Post.vue"),
  },
  {
    path: "/ChargeMeTribe/EditInfo",
    name: "ChargeMeTribe_EditInfo",
    component: () => import("../views/ChargeMeTribe/Member/EditInfo.vue"),
  },
  {
    path: "/ChargeMeTribe/PostPage",
    name: "ChargeMeTribe_PostPage",
    component: () => import("../views/ChargeMeTribe/HomeViewPage_Post.vue"),
  },
  {
    path: "/ChargeMeTribe/AddPost",
    name: "ChargeMeTribe_AddPost",
    component: () => import("../views/ChargeMeTribe/PostCenter/AddPost.vue"),
  },
  {
    path: "/ChargeMeTribe/AddPost/:id",
    name: "ChargeMeTribe_AddPost_id",
    component: () => import("../views/ChargeMeTribe/PostCenter/AddPost.vue"),
  },
  {
    path: "/ChargeMeTribe/Post/:id",
    name: "ChargeMeTribe_Post",
    component: () => import("../views/ChargeMeTribe/PostCenter/Post.vue"),
  },
  {
    path: "/ChargeMeTribe/PostList",
    name: "ChargeMeTribe_PostList",
    component: () => import("../views/ChargeMeTribe/PostCenter/PostList.vue"),
  },
  {
    path: "/ChargeMeTribe/ClassPage",
    name: "ChargeMeTribe_ClassPage",
    component: () => import("../views/ChargeMeTribe/HomeViewPage_Class.vue"),
  },
  {
    path: "/ChargeMeTribe/Search",
    name: "ChargeMeTribe_Search",
    component: () => import("../views/ChargeMeTribe/SearchPage.vue"),
  },
  {
    path: "/ChargeMeTribe/Class/:id",
    name: "ChargeMeTribe_Class",
    component: () => import("../views/ChargeMeTribe/ClassPage.vue"),
  },
  {
    path: "/ChargeMeTribe/TeacherIntroduce/:id",
    name: "ChargeMeTribe_TeacherIntroduce",
    component: () => import("../views/ChargeMeTribe/TeacherIntroducePage.vue"),
  },
  {
    path: "/ChargeMeTribe/TeacherCenter",
    name: "ChargeMeTribe_TeacherCenter",
    component: () => import("../views/ChargeMeTribe/TeacherCenter/TeacherCenter.vue"),
    children: [
      {
        path: "TeacherApply",
        name: "ChargeMeTribe_TeacherApply",
        component: () => import("../views/ChargeMeTribe/TeacherCenter/TeacherApply.vue"),
      },
      {
        path: "TeacherProfile",
        name: "ChargeMeTribe_TeacherProfile",
        component: () => import("../views/ChargeMeTribe/TeacherCenter/TeacherProfile.vue"),
      },
      {
        path: "TeacherClass",
        name: "ChargeMeTribe_TeacherClass",
        component: () => import("../views/ChargeMeTribe/TeacherCenter/TeacherClass.vue"),
      },
      {
        path: "TeacherCalendar",
        name: "ChargeMeTribe_TeacherCalendar",
        component: () => import("../views/ChargeMeTribe/TeacherCenter/TeacherCalendar.vue"),
      },
    ],
  },
  {
    path: "/ChargeMeTribe/StudentCenter",
    name: "ChargeMeTribe_StudentCenter",
    redirect: "/ChargeMeTribe/StudentCenter/StudentProfile",
    component: () => import("../views/ChargeMeTribe/StudentCenter/StudentCenter.vue"),
    children: [
      {
        path: "StudentProfile",
        name: "ChargeMeTribe_StudentProfile",
        component: () => import("../views/ChargeMeTribe/StudentCenter/StudentProfile.vue"),
      },
      {
        path: "StudentClass",
        name: "ChargeMeTribe_StudentClass",
        component: () => import("../views/ChargeMeTribe/StudentCenter/StudentClass.vue"),
      },
      {
        path: "StudentCalendar",
        name: "ChargeMeTribe_StudentCalendar",
        component: () => import("../views/ChargeMeTribe/StudentCenter/StudentCalendar.vue"),
      },
    ],
  },
  {
    path: "/ChargeMeTribe/Connection",
    name: "ChargeMeTribe_Connection",
    component: () => import("../views/ChargeMeTribe/Connection/Connection.vue"),
  },

  // 聯絡簿 ChargeMeContactBook -------------------------------------------
  {
    path: "/ChargeMeContactBook/Note",
    name: "ChargeMeContactBook_Note",
    component: () => import("../views/ChargeMeContactBook/Note/Note.vue"),
    props: true,
  },
  {
    path: "/ChargeMeContactBook/Note1",
    name: "ChargeMeContactBook_Note1",
    component: () => import("../views/ChargeMeContactBook/Note/Note1.vue"),
    props: true,
  },
  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("../views/ChargeMeContactBook/Privacy/Privacy.vue"),
  },
  {
    path: "/ChargeMeContactBook/Payment",
    name: "ChargeMeContactBook_Payment",
    // component: () => import("../views/ChargeMeContactBook/Payment/Payment.vue"), //TODO:暫時改成新的付款頁面，之後要改回來
    component: () => import("../views/ChargeMeContactBook/Payment/PaymentTemp.vue"),
    props: true,
  },
  {
    path: "/ChargeMeContactBook/LineBot",
    name: "ChargeMeContactBook_LineBot",
    component: () => import("../views/ChargeMeContactBook/LineBot/LineBot.vue"),
    props: true,
  },
  {
    path: "/ChargeMeContactBook/HomeWork",
    name: "ChargeMeContactBook_HomeWork",
    component: () => import("../views/ChargeMeContactBook/HomeWork/HomeWork.vue"),
  },

  {
    path: "/ChargeMeContactBook/Curriculum",
    name: "ChargeMeContactBook_Curriculum",
    component: () => import("../views/ChargeMeContactBook/ClassRoom/Curriculum_New.vue"),
    props: true,
  },
  {
    path: "/ChargeMeContactBook/ClassRoomCenter",
    name: "ChargeMeContactBook_ClassRoomCenter",
    component: () => import("../views/ChargeMeContactBook/ClassRoom/ClassRoomCenter.vue"),
    props: true,
    children: [
      {
        name: "ChargeMeContactBook_ClassRoom",
        path: "ClassRoom/:id",
        component: () => import("../views/ChargeMeContactBook/ClassRoom/ClassRoom.vue"),
        props: true,
        children: [
          {
            name: "ChargeMeContactBook_ContactBook",
            path: "ContactBook/:contactBookId",
            component: () => import("../views/ChargeMeContactBook/ClassRoom/ClassRoom.vue"),
            props: true,
          },
          {
            name: "ChargeMeContactBook_ContactBookView",
            path: "ContactBookView/:role/:contactBookId",
            component: () => import("../views/ChargeMeContactBook/ClassRoom/ClassRoom.vue"),
            props: true,
          },
          {
            name: "ChargeMeContactBook_ContactBookViewById",
            path: "ContactBookView/:role/:contactBookId/:todoId",
            component: () => import("../views/ChargeMeContactBook/ClassRoom/ClassRoom.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/ChargeMeContactBook/Message",
    name: "ChargeMeContactBook_Message",
    component: () => import("../views/ChargeMeContactBook/Message/MainMessage.vue"),
  },
  //企業gpt----------------------------
  {
    path: "/ChargeMeEnterpriseGPT/home",
    name: "EnterpriseGPTHome",
    component: () => import("../views/ChargeMeEnterpriseGPT/Home.vue"),
  },
  {
    path: "/ChargeMeEnterpriseGPT/ResponseLineBot",
    name: "ResponseLineBotGPT",
    component: () => import("../views/ChargeMeEnterpriseGPT/Home.vue"),
  },
  {
    path: "/ChargeMeEnterpriseGPT/ResponseLineBot/Chat",
    name: "ResponseLineBotGPTChat",
    component: () => import("../views/ChargeMeEnterpriseGPT/Home.vue"),
  },
  {
    path: "/ChargeMeEnterpriseGPT/ResponseLineBot/Contact",
    name: "ResponseLineBotGPTContact",
    component: () => import("../views/ChargeMeEnterpriseGPT/Home.vue"),
  },
  {
    path: "/ChargeMeEnterpriseGPT/ResponseLineBot/FilterMessage",
    name: "ResponseLineBotGPTFilterMessage",
    component: () => import("../views/ChargeMeEnterpriseGPT/Home.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to.path);
  // 推薦碼
  if (to.query.ReferralCode != undefined) Storage.set("referralCode", { referralCode: to.query.ReferralCode });

  // Email & Line 綁定
  if (to.path == "/EmailVerify" || to.path == "/BindLine") next();

  if (to.path == "/" || to.path.toLowerCase() == "/enterpriselogin" || to.path.toLowerCase() == "/chargemehome" || to.path == "/LineAuth" || to.path == "/google-callback") {
    next();
  } else {
    const publicPages = ["/", "/Home", "/ChargeMeTribe/TeacherIntroduce", "/ChargeMeTribe/Class", "/login", "/login/register", "/login/forgetPassword", "/login/resetPassword", "/login/verifyEmail", "/About", "/ChargeMeContactBook/LineBot", "/LineAuth", "/google-callback", "/privacy", "/EnterpriseLogin"];
    var authRequired = !publicPages.includes(to.path);
    var loggedIn = localStorage.getItem("user");
    var tempRedirectPath = localStorage.getItem("tempRedirectPath");

    if (to.path.includes("/ChargeMeTribe/TeacherIntroduce") || (to.path.includes("/ChargeMeTribe/Class") && !to.path.includes("/ChargeMeContactBook/ClassRoomCenter"))) authRequired = false;
    if (authRequired && !loggedIn) {
      localStorage.setItem("tempRedirectPath", JSON.stringify({ path: to.path }));

      if (to.path.toLowerCase().includes("enterprise")) {
        next("/EnterpriseLogin");
      } else {
        next("/login");
      }
    } else {
      var loginUser = JSON.parse(loggedIn);
      var redirectPath = JSON.parse(tempRedirectPath);
      if (loggedIn != null && (loginUser.email == "" || loginUser.email == undefined || loginUser.email == null)) {
        next("/EmailVerify");
      } else if (loggedIn != null && loginUser.isEmailVerified && !loginUser.isLineVerified && loginUser.remindBindLine && loginUser.tempRemindBindLine) {
        next("/BindLine");
      } else if ((to.path != "/login" || to.path.toLowerCase() != "/enterpriselogin") && redirectPath != null && redirectPath != undefined && redirectPath.path != "" && authRequired) {
        localStorage.removeItem("tempRedirectPath");
        next(redirectPath.path);
      } else {
        next();
      }
    }
  }
});

export default router;
