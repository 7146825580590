import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import util from "./utils/utils";
import singnalRutils from "./utils/signalR";
import axios from "axios";
import VueAxios from "vue-axios";
import vue3GoogleLogin from "vue3-google-login";
import VueGtag from "vue-gtag";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";

// ElementUI Plus
import ElementPlus from "element-plus";
import zhTw from "element-plus/es/locale/lang/zh-tw";
import "element-plus/dist/index.css";

// All Scope CSS
import "./assets/css/reset.css";
import "./assets/css/CommonSetting.css";
import "./assets/css/autofill.css";
import i18n from "./i18n/i18n.js";
// All Scope SCSS
import "./assets/scss/ElementUISetting.scss";

import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";

import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.$eventHub = createApp({});

app
	.use(store)
	.use(singnalRutils)
	.use(util)
	.use(ElementPlus, {
		locale: zhTw,
	})
	.use(i18n)
	.use(ContextMenu)
	.use(VueAxios, axios)
	.use(vue3GoogleLogin, {
		clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
	})
	.use(
		VueGtag,
		{
			config: { id: "G-T596HD23PR" },
		},
		router
	)
	.use(VideoPlayer)
	.use(router)
	.use(pinia)
	.mount("#app");
router.afterEach(() => {
	window.scrollTo(0, 0);
});
