<template>
	<Loading :isLoading="$store.state.isloading" />
	<Navbar ref="childMsg  " v-if="navbarShow" />
	<div id="view" :class="{ IsMobileSize: $store.getters.mobileSize, needPaddingBottom: !$route.path.toLowerCase().includes('enterprise') }">
		<router-view v-if="isRouterAlive" />
	</div>
	<Foot v-if="footerShow" />
</template>

<script>
import Navbar from "./layout/Navbar.vue";
import Foot from "./layout/Footer";
import Loading from "./components//Common/CommonElement/LoadingView.vue";
import Storage from "../src/models/storage";

export default {
	name: "ChargeMe",
	components: {
		Navbar,
		Foot,
		Loading,
	},
	provide() {
		return {
			reload: this.reload,
		};
	},
	data() {
		return {
			isRouterAlive: true,
		};
	},
	methods: {
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(() => {
				this.isRouterAlive = true;
			});
		},
		updateBackgroundColor() {
			// 根据当前路由设置不同的背景色
			console.log(this.$route.path);
			switch (this.$route.path.toLowerCase()) {
				case "/chargemetribe":
					document.getElementById("app").style.backgroundColor = "#f4f2ee";
					break;
				default:
					document.getElementById("app").style.backgroundColor = "white";

					break;
			}
		},
	},
	async created() {
		const vm = this;
		// 取得樂器 & 地區 & 類型
		await vm.getInstrument();
		await vm.getDistrict();
		await vm.getTeachingType();
		await vm.getInstrumentType();

		var setting = Storage.get("ChargeMe_Setting");
		if (setting == undefined || setting.page == undefined) {
			Storage.set("ChargeMe_Setting", {
				page: "/ChargeMeContactBook/ClassRoomCenter",
			});
		}
	},
	async mounted() {
		const vm = this;
		vm.$store.state.fullWidth = document.body.clientWidth;
		window.addEventListener("resize", function () {
			vm.$store.state.fullWidth = document.body.clientWidth;
		});

		if (vm.$store.state.auth.user != null) {
			vm.$hubConnection
				.start()
				.then(function () {
					vm.$hubConnection.invoke(
						"UserConnected",
						Number(vm.$store.state.auth.user.id)
					);
				})
				.catch((error) => console.error(error));
		}

		document.documentElement.style.setProperty("--main-color", "#0000ff");

		this.updateBackgroundColor();
	},
	computed: {
		footerShow() {
			let noShowArray = ["Login", "ClassRoomCenter"];
			var canFooterShow = true;
			noShowArray.forEach((item) => {
				if (this.$route.path.toLowerCase().includes(item.toLowerCase()))
					canFooterShow = false;
			});
			return canFooterShow;
		},
		navbarShow() {
			let noShowArray = ["Login", "ClassRoomCenter", "HomeWork"];
			var canNavbarShow = true;
			noShowArray.forEach((item) => {
				if (this.$route.path.toLowerCase().includes(item.toLowerCase()))
					canNavbarShow = false;
			});
			return canNavbarShow;
		},
	},
	watch: {
		"$store.state.auth.user": function () {
			let vm = this;
			vm.$nextTick(() => {
				if (vm.$store.state.auth.user != null) {
					vm.$hubConnection
						.start()
						.then(function () {
							vm.$hubConnection.invoke(
								"UserConnected",
								Number(vm.$store.state.auth.user.id)
							);
						})
						.catch(function (err) {
							console.log("連線錯誤: " + err.toString());
						});
				}
			});
		},
		"$route.path"(newVal) {
			var result;
			if (newVal.includes("ChargeMeContactBook"))
				result = "/ChargeMeContactBook/ClassRoomCenter";
			else if (newVal.includes("ChargeMeTribe")) result = "/ChargeMeTribe";
			else return;

			Storage.set("ChargeMe_Setting", {
				page: result,
			});
		},
		$route() {
			this.updateBackgroundColor();
		},
	},
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
#app {
	font-family: "Roboto Condensed", serif;
	font-optical-sizing: auto;
	font-style: normal;
	text-align: center;
	color: $text_color;
	position: relative;

	display: flex;
	flex-direction: column;
}

#view {
	flex-grow: 1;
	margin-top: 50px;
}

.IsMobileSize.needPaddingBottom {
	padding-bottom: 76.8px;
}
</style>
